// extracted by mini-css-extract-plugin
export var africarColor = "c6_xT";
export var ammoColor = "c6_xz";
export var babelColor = "c6_xS";
export var cakerColor = "c6_xD";
export var chatticColor = "c6_xB";
export var danColor = "c6_xC";
export var dumaColor = "c6_xM";
export var dvColor = "c6_xW";
export var impactColor = "c6_xH";
export var ixColor = "c6_xX";
export var malleniColor = "c6_xG";
export var pmkColor = "c6_xR";
export var refColor = "c6_xN";
export var rewColor = "c6_xP";
export var timebotColor = "c6_xK";
export var tradeaboatColor = "c6_xL";
export var tradervsColor = "c6_xJ";
export var variaColor = "c6_xF";
export var wownerColor = "c6_xQ";
export var xpcColor = "c6_xV";